import {useTranslation, withTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import {footerUrls} from 'config';
import css from './Footer.scss';

const Footer = () => {
    const {t} = useTranslation('lle');
    const {selectedLang, changeLanguage, scopedLangCodes} = useI18nContext();
    const langs = scopedLangCodes.filter(lang => lang !== selectedLang);
    const locale = getStoredLang().toLowerCase();
    const webinarUrl = window.location.hostname;
    const isOneApp = webinarUrl === process.env.ONEAPP_URL;
    const logoKey = isOneApp ? 'oneappLogo' : 'logo';

    return (
        <footer className={css.root}>
            <div className={css.footerFlow}>
                {!isOneApp && (
                    <>
                        <a href="/#/" className={css.logoWrapper}>
                            <Image
                                classNameRoot={css.logoWrapper}
                                cloudinaryImageId="coach/livongo_by_teladoc_logo_t4rntd"
                                width={124}
                                height={30}
                                role="button"
                                alt={t(logoKey)}
                                aria-label={t(logoKey)}
                            />
                        </a>
                        <div className={css.copyBlock}>
                            <span className={css.footerBlock}>
                                {new Date().getFullYear()} &copy; Livongo{' '}
                                {t('footer.copyright')}
                            </span>
                        </div>
                    </>
                )}

                {isOneApp && (
                    <>
                        <a href="/#/" className={css.logoWrapper}>
                            <Image
                                classNameRoot={css.logoWrapper}
                                cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                                width={125}
                                height={40}
                                role="button"
                                alt={t(logoKey)}
                                aria-label={t(logoKey)}
                            />
                        </a>
                        <div className={css.copyBlock}>
                            <span className={css.footerBlock}>
                                {new Date().getFullYear()} &copy; Teladoc Health{' '}
                                {t('footer.copyright')}
                            </span>
                        </div>
                    </>
                )}
                <div className={css.termsBlock}>
                    {langs.map(lang => (
                        <a
                            key={lang}
                            href="#"
                            onClick={() => {
                                changeLanguage(lang);
                            }}
                        >
                            {selectedLang === 'en-US'
                                ? 'EN ESPAÑOL'
                                : 'IN ENGLISH'}
                        </a>
                    ))}
                    <span>•&nbsp;</span>
                    <span className={css.footerBlock}>
                        PL00940 Rev A&nbsp;•&nbsp;
                    </span>
                    {footerUrls.map((obj, i) => {
                        return (
                            <a
                                key={i}
                                className={[css.action, css.blockLink]}
                                href={obj.lang[locale]}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t(`footer.${obj.name}`)}
                                <span>•&nbsp;</span>
                            </a>
                        );
                    })}
                </div>
            </div>
        </footer>
    );
};

export default withTranslation()(Footer);
