import APIUtils from '@livongo/utils/api';

const WebinatoAPIUtils = APIUtils.init({
    baseUrl: process.env.MV_URL,
    additionalHeaders: {
        'Content-Type': 'application/json',
    },
});

function getAllEvents() {
    return new Promise((resolve, reject) => {
        WebinatoAPIUtils.post('v1/users/me/webinato/events').then(data =>
            resolve(data.data)
        );
    });
}

function eventRegister(info) {
    return new Promise((resolve, reject) => {
        WebinatoAPIUtils.post(
            '/v1/users/me/webinato/groupEventRegister',
            info,
            null,
            {
                useMW: true,
            }
        ).then(data => resolve(data.data));
    });
}

function deleteInvitee(inviteeID) {
    return new Promise((resolve, reject) => {
        WebinatoAPIUtils.get(
            '/v1/users/me/webinato/deleteInvitee/',
            inviteeID,
            null,
            null,
            {useMW: true}
        ).then(data => resolve(data.data));
    });
}

export const WebinatoResources = {
    eventRegister,
    deleteInvitee,
    getAllEvents,
};
