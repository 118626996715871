import PropTypes from 'prop-types';
import {useState, useRef} from 'react';
import isUndefined from 'lodash/isUndefined';
import format from 'date-fns/format';
import useMounted from '@livongo/hooks/useMounted';
import Modal from '@teladoc/pulse/ui/Modal';
import Button from '@teladoc/pulse/ui/Button';
import Form from '@teladoc/pulse/ui/Form';
import TextInput from '@teladoc/pulse/ui/TextInput';
import Label from '@teladoc/pulse/ui/Label';
import ValidationUtils from '@livongo/utils/validation';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import {useTranslation} from 'react-i18next';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import MixpanelUtils from '@livongo/utils/mixpanel';
import FormSaveError from 'common/form-save-error/FormSaveError';
import {WebinatoResources} from '../common/WebinatoResources';
import css from './EventModal.scss';

const EventModal = ({event, show, setShow, onHidden, isOpen, setIsOpen}) => {
    const formRef = useRef(null);
    const {t} = useTranslation('lle');
    const {isMounted} = useMounted();
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const [eventToRegister, setEventToRegister] = useState({});
    const [savedError, setSavedError] = useState(null);
    const [registerForm, setRegisterForm] = useState({});
    const webinarUrl = window.location.hostname;
    const isOneApp = webinarUrl === process.env.ONEAPP_URL;
    const deletePrevInv = (inviteeID, eventDeleted) => {
        WebinatoResources.deleteInvitee(inviteeID).then(data => {
            eventDeleted.loading = false;
            eventDeleted.attended = true;
            eventDeleted.inviteeEmailChanged = '';
        });
    };
    const errorDisplay = err => {
        // eslint-disable-next-line no-console
        console.log(err);
    };

    const attendEvent = eventAttended => {
        let userEmail = registerForm.email;

        if (eventAttended.changeEmail) {
            userEmail = eventAttended.inviteeEmailChanged;
        }

        if (isUndefined(userEmail)) {
            // mixpanel track
            MixpanelUtils.track(
                'Webinar v2.0 - Error: User email is not set- he cannot sign up'
            );

            return;
        }

        // trim email before sending
        userEmail = userEmail.replace(/\s/g, '');

        const eventToSend = {
            registrantInfo: `<registrants> <registrant><firstName>${
                registerForm.firstName || 'there'
            }</firstName><lastName>${
                registerForm.lastName || ''
            }</lastName><email>${userEmail}</email></registrant></registrants>`,
            eventInfo: `<events><event><eventID>${eventAttended.eventID[0]}</eventID> <registeredEventDate></registeredEventDate> </event> </events>`,
            sendEmail: 1,
        };

        return WebinatoResources.eventRegister(eventToSend)
            .then(data => {
                if (eventAttended.changeEmail) {
                    eventAttended.changeEmail = false;
                    // delete previews invitee
                    deletePrevInv(eventAttended.inviteeID, eventAttended);

                    // mixpanel track
                    MixpanelUtils.track(
                        'Webinar v2.0 - User changed his defaut email for an event'
                    );
                } else {
                    eventAttended.loading = false;
                    eventAttended.attended = true;

                    // mixpanel track
                    MixpanelUtils.track(
                        'Webinar v2.0 - User choose to attend to an event'
                    );
                }
                eventAttended.inviteeID = data.root.status[0].inviteeID[0];
                eventAttended.inviteeEmail = data.root.status[0].email[0];
                setShow(false);
                setIsOpen(false);
            })
            .catch(err => {
                setSavedError(err);
                eventAttended.loading = false;
            });
    };

    const onSubmit = async ({data: formData}) => {
        if (!formRef.current.isValid()) {
            return;
        }
        setSavedError(null);
        const form = formRef.current;
        const formValue = form.submit();

        try {
            if (isMounted) {
                if (emailConfirmed) {
                    await attendEvent(eventToRegister);
                } else {
                    MixpanelUtils.track({
                        event: 'Webinar v2.0 - Confirm spot in this webinar',
                    });
                    setRegisterForm(formValue);
                }
                setEmailConfirmed(true);
            }
        } catch (e) {
            setEmailConfirmed(false);
            MixpanelUtils.track({
                event: 'Webinar v2.0 - Fail confirm spot in this webinar',
            });
            errorDisplay(e);
        }
    };

    const onCancelClick = () => {
        setShow(false);
        setIsOpen(false);
    };

    const eventName = isOneApp
        ? event.event_name[0].replace('Livongo', 'Teladoc Health')
        : event.event_name;

    return show ? (
        <Modal
            isOpen={isOpen}
            classNameContainer={[css.long, css.modalPrograms]}
            title={t('eventModal.title')}
            onHidden={onHidden}
        >
            <div style={{padding: 0}} />
            <div>
                <h3 id="signup-modal-title" className={css.modalEventTitle}>
                    {eventName}
                </h3>
                <div className={css.timeDescription}>
                    {format(
                        new Date(event.utc_utime * 1000),
                        'MMM dd yyyy, hh:mm a'
                    )}
                </div>
                <div className={css.timeDescription}>{event.shortDesc}</div>
            </div>
            <HorizontalRule className={css.hr} />
            <Form id="eventInfo" ref={formRef} onSubmit={onSubmit}>
                {!emailConfirmed && (
                    <div>
                        <div className="confirm-question">
                            {t('eventModal.subtitle')}
                        </div>
                        <TextInput
                            id="firstName"
                            name="firstName"
                            classNameRoot={[css.formField, css.firstName]}
                            label={
                                <Label i18nRequiredVisualLabel=" ">
                                    {t('eventModal.firstName')}
                                </Label>
                            }
                            error={
                                <FormElementError>
                                    {t('eventModal.errorMsgFirstName')}
                                </FormElementError>
                            }
                            required
                        />
                        <TextInput
                            id="lastName"
                            name="lastName"
                            classNameRoot={[css.formField, css.lastName]}
                            label={
                                <Label i18nRequiredVisualLabel=" ">
                                    {t('eventModal.lastName')}
                                </Label>
                            }
                            error={
                                <FormElementError>
                                    {t('eventModal.errorMsgLastName')}
                                </FormElementError>
                            }
                            required
                        />
                        <TextInput
                            id="email"
                            name="email"
                            type="email"
                            label={
                                <Label i18nRequiredVisualLabel=" ">Email</Label>
                            }
                            pattern={ValidationUtils.EMAIL}
                            error={
                                <FormElementError>
                                    {t('programs.invalidEmail')}
                                </FormElementError>
                            }
                            required
                        />
                    </div>
                )}
                {emailConfirmed && (
                    <div className="row">
                        <div className="onfirm-question">
                            {t('eventModal.confirmWebinar')}
                        </div>
                    </div>
                )}
                {savedError && <FormSaveError error={savedError} />}
                <div className={css.actions}>
                    <Button
                        variant="secondary"
                        size="small"
                        className={css.cancel}
                        onClick={onCancelClick}
                    >
                        {t('eventModal.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        size="small"
                        onClick={() => setEventToRegister(event)}
                    >
                        {t('eventModal.confirm')}
                    </Button>
                </div>
            </Form>
        </Modal>
    ) : (
        ''
    );
};

EventModal.propTypes = {
    event: PropTypes.object,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onHidden: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
};

export default EventModal;
