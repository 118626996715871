const zendeskUrl1 = `https://livongo.zendesk.com/hc/`;
const zendeskUrl2 = `/sections/360001774334-Livongo-Learning-Events`;
const Config = {
    accessTokenCookie: 'access_token',
};
const footerUrls = [
    {
        name: 'websiteAndMobilePrivacyPolicy',
        lang: {
            'en-us': 'https://www.teladochealth.com/privacy-policy/',
            'es-us': 'https://teladochealth.com/es/privacy-policy/',
        },
    },
    {
        name: 'legalDisclaimer',
        lang: {
            'en-us': 'https://www.teladochealth.com/legal-disclaimer/',
            'es-us': 'https://teladochealth.com/es/legal-disclaimer/',
        },
    },
    {
        name: 'noticeOfNonDiscriminationAndLanguageAssistance',
        lang: {
            'en-us':
                'https://www.teladochealth.com/notice-of-non-discrimination/',
            'es-us':
                'https://teladochealth.com/es/notice-of-non-discrimination/',
        },
    },
];

export {Config, footerUrls, zendeskUrl1, zendeskUrl2};
