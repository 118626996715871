import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';

function formatError(error) {
    const details = error.data?.details;

    if (details) {
        return {message: details};
    }

    // non API provided error messages would be something like a 404 where axios is providing the error message instead
    const nonApiError = error.data?.message;

    if (nonApiError) {
        return {message: nonApiError};
    }

    return error;
}

const FormSaveError = ({error, title}) => {
    if (!error) {
        return null;
    }

    const defaultTitle = isArray(error)
        ? 'Whoops, there were some errors'
        : 'Uh oh, there was an error';

    return (
        <FormGroupError
            title={title || defaultTitle}
            errors={formatError(error)}
        />
    );
};

FormSaveError.propTypes = {
    error: PropTypes.object,
    title: PropTypes.string,
};

export default FormSaveError;
