import {Suspense} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import App from './App';

const Root = props => {
    return (
        <Router>
            <Suspense>
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Suspense>
        </Router>
    );
};

export default Root;
