export const BUILD_KEY = process.env.BUILD_KEY;
export const BUILD_ID = process.env.BUILD_ID;

export default function init({lang}) {
    /* See default config from @livongo/fe-ui in the link below
     * https://github.com/livongo/fe-ui/blob/master/src/Context/i18n/init.js#L53
     */
    return {
        /*
         * Load messages ahead of time since they used in the flyout
         *  and, flyout has to deal with time delay.
         */
        defaultNS: 'lle',
        ns: ['lle'],
        resources: {
            'en-US': {
                lle: require('i18n/locales/en-US.json'),
            },
            'es-US': {
                lle: require('i18n/locales/es-US.json'),
            },
        },
        lng: lang,
    };
}

const languageCodes = {
    default: 'en-US',
    'en-us': 'en-US',
    'es-us': 'es-US',
};

export const getLanguageCode = lang => {
    let languageCode;

    if (lang) {
        languageCode = languageCodes[lang.toLowerCase()];
    }

    if (!languageCode) {
        languageCode = languageCodes.default;
    }

    return languageCode;
};
