import Programs from 'programs/Programs';
import Header from 'header/Header';
import Footer from 'footer/Footer';
import css from './App.scss';

const App = props => {
    return (
        <div className={css.root}>
            <Header />
            <Programs />
            <Footer />
        </div>
    );
};

export default App;
