import {useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import css from './Header.scss';

const Header = () => {
    const {t} = useTranslation('lle');
    const webinarUrl = window.location.hostname;
    const isOneApp = webinarUrl === process.env.ONEAPP_URL;

    if (isOneApp) {
        document.title = 'Teladoc Health Learning Events';
        document.querySelector("link[rel*='icon']").href =
            'https://assets.livongo.com/image/upload/v1665515366/registration/common/favicon/TeledocFavIcon.png';
    }

    return (
        <header className={css.root}>
            {!isOneApp && (
                <>
                    <div className={css.navbarWebinar}>
                        <Image
                            classNameRoot={css.logo}
                            cloudinaryImageId="coach/livongo_by_teladoc_logo_t4rntd"
                            width={146}
                            height={37}
                            role="button"
                            alt=""
                        />
                    </div>
                    <div>
                        <div className={css.navbarInfo}>
                            <a href="tel:18009454355">1-800-945-4355</a>
                            <br />
                            <span className={css.finePrint}>
                                <span>{t('header.help')}</span>
                            </span>
                        </div>
                    </div>
                </>
            )}
            {isOneApp && (
                <>
                    <div className={css.navbarWebinar}>
                        <Image
                            classNameRoot={css.logo}
                            cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                            width={125}
                            height={40}
                            role="button"
                            alt=""
                        />
                    </div>
                    <div>
                        <div className={css.navbarInfo}>
                            <a href="tel:18008352362">1-800-835-2362</a>
                            <br />
                            <span className={css.finePrint}>
                                <span>{t('header.help')}</span>
                            </span>
                        </div>
                    </div>
                </>
            )}
        </header>
    );
};

export default Header;
